import LineGradient from "../components/LineGradient";
import useMediaQuery from "../hooks/useMediaQuery";
import { motion } from "framer-motion";

const MySkills = () => {
  const isAboveMediumScreens = useMediaQuery("(min-width: 1060px)");

  return (
    <section id="skills" className="pt-10 pb-24">
      {/* HEADER AND IMAGE SECTION */}
      <div className="md:flex md:justify-between md:gap-16 mt-32">
        <motion.div
          className="md:w-1/3"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, x: -50 },
            visible: { opacity: 1, x: 0 },
          }}
        >
          <p className="font-playfair font-semibold text-4xl mb-5">
            My <span className="text-blue">SKILLS</span>
          </p>
          <LineGradient width="w-2/3"/>
          <p className="text-2xl mt-10 mb-7">
            These are things I love doing!
          </p>
        </motion.div>

        <div className="mt-16 md:mt-0">
          {isAboveMediumScreens ? (
            <div
              className="relative z-0 ml-20 before:absolute before:-top-10 before:-left-10
                before:w-full before:h-full 
                before:border-2 before:border-blue before:z-[-1]"
            >
              <img
                alt="skills"
                className="z-10"
                src="assets/csimage.png"
              />
            </div>
          ): (
            <img
                alt="skills"
                className="z-10"
                src="assets/csimage.png"
              />
          )}
          {/* <ComputersCanvas/> */}
        </div>
      </div>
      {/* SKILLS */}
      <div className="md:flex md:justify-between mt-16 gap-32">
        {/*Experience */}
        <motion.div
          className="md:w-1/3 mt-10"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
          }}
        >
          <div className="relative h-32">
            <div className="z-10">
              <p className="font-playfair font-semibold text-5xl">01</p>
              <p className="font-playfair font-semibold text-3xl mt-3">Front End</p>
            </div>
            {isAboveMediumScreens ? (
              <div className="w-1/2 md:w-3/4 h-32 bg-blue absolute right-0 top-0 z-[-1]" />
            ) : (
              <div className="w-2/3 md:w-3/4 h-32 bg-blue absolute right-5 top-0 z-[-1]" />
            )}
          </div>
          <ul className="list-disc list-inside mt-2">
            <li>React</li>
            <li>JavaScript</li>
            <li>HTML</li>
            <li>CSS</li>
            <li>TailwindCSS</li>
            <li>Three.js</li>
          </ul>
        </motion.div>


        {/* INNOVATIVE */}
        <motion.div
          className="md:w-1/3 mt-10"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ delay:0.2, duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
          }}
        >
          <div className="relative h-32">
            <div className="z-10">
              <p className="font-playfair font-semibold text-5xl">02</p>
              <p className="font-playfair font-semibold text-3xl mt-3">Back End</p>
            </div>
            {isAboveMediumScreens ? (
              <div className="w-1/2 md:w-3/4 h-32 bg-red absolute right-0 top-0 z-[-1]" />
            ) : (
              <div className="w-2/3 md:w-3/4 h-32 bg-red absolute right-5 top-0 z-[-1]" />
            )}
          </div>
          <p className="mt-5">
          <li>Node.js</li>
          <li>Flask</li>
          <li>MySQL</li>
          <li>PostgreSQL</li>
          <li>RESTful API</li>
          <li>HTTPS</li>
          </p>
        </motion.div>
        {/* IMMAGINATIVE */}
        <motion.div
          className="md:w-1/3 mt-10"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ delay:0.2, duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
          }}
        >
          <div className="relative h-32">
            <div className="z-10">
              <p className="font-playfair font-semibold text-5xl">03</p>
              <p className="font-playfair font-semibold text-3xl mt-3">Programming Languages</p>
            </div>
            {isAboveMediumScreens ? (
              <div className="w-1/2 md:w-3/4 h-32 bg-yellow absolute right-0 top-0 z-[-1]" />
            ) : (
              <div className="w-2/3 md:w-3/4 h-32 bg-yellow absolute right-5 top-0 z-[-1]" />
            )}
          </div>
          <p className="mt-5">
          <li>C/C++</li>
          <li>MATLAB</li>
          <li>Java</li>
          <li>Python</li>
          </p>
        </motion.div>
      </div>
    </section>
  )
}

export default MySkills;