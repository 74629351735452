import LineGradient from "../components/LineGradient";
import { motion } from "framer-motion";
import { FiGithub } from "react-icons/fi";
import {useState} from "react"

const container = {
  hidden: {},
  visible: {
    transition: {
      staggerChildren: 0.2,
    },
  },
};

const projectVariant = {
  hidden: { opacity: 0, scale: 0.8 },
  visible: { opacity: 1, scale: 1 },
};

const Project = ({ title, description, url, githubUrl }) => {
  const projectTitle = title.split(" ").join("-").toLowerCase();
  const [isHovered, setIsHovered] = useState(false);

  const handleProjectClick = () => {
    window.open(url, "_blank");
  };

  const handleGithubClick = (e) => {
    e.stopPropagation();
    window.open(githubUrl, "_blank");
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <motion.div
      variants={projectVariant}
      className="relative"
      onClick={handleProjectClick}
      role="button"
      tabIndex={0}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className={`absolute h-full w-full opacity-0 ${isHovered ? 'opacity-90' : ''} transition duration-500 bg-grey z-30 flex flex-col justify-center items-center text-center p-16 text-deep-blue`}>
        <p className="text-2xl font-playfair">{title}</p>
        <p className="mt-7">{description}</p>
        {isHovered && (
          <motion.div
            className="absolute top-4 right-4"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <motion.div
              className="relative rounded-full p-1 bg-navy"
              whileHover={{ scale: 1.1 }}
              onClick={handleGithubClick}
            >
              <a href={githubUrl} target="_blank" rel="noopener noreferrer">
                <FiGithub size={24} color="#000" />
              </a>
            </motion.div>
          </motion.div>
        )}
      </div>
      <img src={`../assets/${projectTitle}.jpeg`} alt={projectTitle} />
    </motion.div>
  );
};


const Projects = () => {
  return (
    <section id="projects" className="pt-48 pb-48">
      {/* HEADINGS */}
      <motion.div
        className="md:w-2/5 mx-auto text-center"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.5 }}
        transition={{ duration: 0.5 }}
        variants={{
          hidden: { opacity: 0, y: -50 },
          visible: { opacity: 1, y: 0 },
        }}
      >
        <div>
          <p className="font-playfair font-semibold text-4xl">
            <span className="text-blue">PRO</span>JECTS
          </p>
          <div className="flex justify-center mt-5">
            <LineGradient width="w-2/3" />
          </div>
        </div>
        <p className="text-2xl mt-10 mb-10">
          Following projects showcase my skills and experience through real-world examples of my work. Each project is briefly described with links to
          code repositories and live demos. They reflect my ability to solve complex problems, work with different technologies, and manage projects effectively.
        </p>
      </motion.div>

      {/* PROJECTS */}
      <div className="flex justify-center">
        <motion.div
          className="sm:grid sm:grid-cols-3"
          variants={container}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.2 }}
        >
          {/* ROW 1 */}
          <div className="flex justify-center text-center items-center p-10 bg-red max-w-[400px] max-h-[400px] text-2xl font-playfair font-semibold">
            Click on Project for live demo
          </div>
          <Project
            title="Chat With GPT"
            description="Chat app supporting user-user / user-ChatGPT"
            url="https://chat.hojungkimm.com/"
            githubUrl="https://github.com/hjjyhj/ChatWithGPT"
          />
          <Project
            title="Photo Gallery"
            description="React Photo gallery app that supports photo upload to Firebase"
            url="https://photo.hojungkimm.com"
            githubUrl="https://github.com/hjjyhj/PhotoCards"
          />

          {/* ROW 2 */}
          <Project
            title="Snake"
            description="Recreation of Google Snake game"
            url="https://github.com/hjjyhj/Snake"
            githubUrl="https://github.com/hjjyhj/Snake"
          />
          <Project
            title="TODO App"
            description="React Todo app that supports user registration and login/logout"
            url="https://github.com/hjjyhj/TODO-App"
            githubUrl="https://github.com/hjjyhj/TODO-App"
          />
          <Project
            title="Photostagram"
            description="Instagram-like React app that supports user registration, login/logout, photo upload/delete"
            url="https://github.com/hjjyhj/Photostagram"
            githubUrl="https://www.github.com"
          />

          {/* ROW 3 */}
          <Project
            title="TravelHelper"
            description="Travel Helper React app that shows restaurants, hotels and attractions using Google Maps"
            url="https://travelhelper.hojungkimm.com"
            githubUrl="https://github.com/hjjyhj/TravelHelper"
          />
          {/* <Project
            title="Project 7"
            description="Description for Project 7"
            url="https://www.google.com"
            githubUrl="https://www.github.com"
          /> */}
          <div className="flex justify-center text-center items-center p-10 bg-blue max-w-[400px] max-h-[400px] text-2xl font-playfair font-semibold">
            Hover onto project and click GitHub icon to access the code.
          </div>
        </motion.div>
      </div>
    </section>
  );
};

export default Projects;
