const SocialMediaIcons = () => {
  return (
    <div className="flex justify-center md:justify-start my-10 gap-7">
      <a
        className="hover:opacity-50 transition duration-500"
        href="https://www.linkedin.com/in/hojung-kim123"
        target="_blank"
        rel="noreferrer"
      >
        <img alt="linkedin-link" src="../assets/linkedin.png" />
      </a>
      <a
        className="hover:opacity-50 transition duration-500"
        href="https://www.github.com/hjjyhj"
        target="_blank"
        rel="noreferrer"
      >
        <img 
          alt="github-link" 
          src="../assets/githublogo.png" 
          style={{ width: '1.9rem', height: '1.9rem' }}  // use inline styles for precise measurements
        />
      </a>

      {/* <a
        className="hover:opacity-50 transition duration-500"
        href="https://www.facebook.com"
        target="_blank"
        rel="noreferrer"
      >
        <img alt="facebook-link" src="../assets/facebook.png" />
      </a> */}
      <a
        className="hover:opacity-50 transition duration-500"
        href="https://www.instagram.com/iamjohnkimm/"
        target="_blank"
        rel="noreferrer"
      >
        <img alt="instagram-link" src="../assets/instagram.png" />
      </a>
    </div>
  );
};

export default SocialMediaIcons;