import React, { useCallback } from "react";
import Particles from "react-tsparticles";
import particlesOptions from "./particles.json"; // make sure this path is correct

const Background = () => {
  const particlesInit = useCallback((main) => {
    console.log(main);
    // you can initialize the tsParticles instance (main) here, adding custom shapes or presets
  }, []);

  const particlesLoaded = useCallback((container) => {
    console.log(container);
  }, []);

  return (
    <Particles
      id="tsparticles"
      init={particlesInit}
      loaded={particlesLoaded}
      options={particlesOptions}
    />
  );
};

export default Background;
