import LineGradient from "../components/LineGradient";
import {motion} from "framer-motion";
import {ComputersCanvas} from "./3D";
import useMediaQuery from "../hooks/useMediaQuery";

const Testimonials = () => {
  const testimonialStyles = `mx-auto relative max-w-[450px] h-[400px] flex flex-col
    justify-end p-16 mt-48 before:absolute before:top-[-120px] before:-ml-[110px] before:left-1/2`;

    const imageStyles = 'w-10 h-10';

    const isAboveMediumScreens = useMediaQuery("(min-width: 1060px)");
    return (
      <section id="testimonials" className="pt-20 pb-16">
        {/* HEADING */}
        <div className="md:flex md:justify-between md:gap-16 mt-0">
          <motion.div
            className="md:w-1/2" // use 1/2 instead of 1/3 to give more space to the ComputersCanvas
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.5 }}
            transition={{ duration: 0.5 }}
            variants={{
              hidden: { opacity: 0, x: -50 },
              visible: { opacity: 1, x: 0 },
            }}
          >
            <div>
              <p className="font-playfair font-semibold text-4xl mb-5 text-blue">
                Experience
              </p>
              <LineGradient width="mx-auto ml-0 w-2/5"/>
              <p className="mt-10 mb-7 text-2xl">
                What I have done so far
              </p>
            </div>
          
        </motion.div>

          <div className="mt-16 md:mt-0 -mb-10">
            {isAboveMediumScreens ? (
              <div
                className="relative z-0 ml-20 before:absolute before:-top-10 before:-left-10
                  before:w-full before:h-full 
                  before:border-2 before:border-blue before:z-[-1]"
              >
                <img
                  alt="skills"
                  className="z-10"
                  src="assets/skills-image.png"
                />
              </div>
            ): (
              <img
                  alt="skills"
                  className="z-10"
                  src="assets/skills-image.png"
                />
            )}
            {/* <ComputersCanvas/> */}
          </div>
        </div>
        {/* TESTIMONIALS */}
        <div className="md:flex md:justify-between gap-8">
          <motion.div
              className={`bg-blue ${testimonialStyles}`}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.5 }}
              transition={{ duration: 0.5 }}
              variants={{
                hidden: { opacity: 0, x: -50 },
                visible: { opacity: 1, x: 0 },
              }}
            >
              {isAboveMediumScreens ? (
                <>
                  <div className="flex items-center ml-5 -mb-11 ">
                    <img src="./assets/brevy-icon.avif" className={`${imageStyles} mr-4 mb-10`} alt="uofm Icon" />
                    <h2 className="text-center text-2xl mb-10">Software Engineer Intern</h2>
                  </div>
                  <ul className="list-disc list-inside text-left text-l -mb-14">
                    <li>Developed Typescript conditions / resolvers to categorize and respond to customer emails for e-commerce companies, utilizing OpenAI API for generating accurate and responses.</li>
                    <li>Enhanced existing conditions / resolvers to rectify incorrect responses.</li>
                    <li>Utilized Retool, AWS, JS and PostgreSQL to create user-friendly interfaces, optimize functionality, and streamline communication between our company and e-commerce partners.</li>
                  </ul>
              </>
            ) : (
              <>
                <div className="flex items-center ml-5 -mb-11">
                  <img src="./assets/brevy-icon.avif" className={`${imageStyles} mr-4 mb-10`} alt="uofm Icon" />
                  <h2 className="text-center text-xl mb-10">Software Engineer Intern</h2>
                </div>
                <ul className="list-disc list-inside text-left text-l -mb-10">
                  < li>Developed Typescript conditions / resolvers, utilizing OpenAI API for generating accurate responses.</li>
                    {/* <li>Enhanced existing conditions / resolvers to rectify incorrect responses.</li> */}
                    <li>Utilized Retool, AWS, JS and PostgreSQL to create user-friendly interfaces, optimize functionality, and streamline communication between our company and e-commerce partners.</li>
                </ul>
              </>
            )}

          </motion.div>
          <motion.div
            className={`bg-red ${testimonialStyles}`}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.5 }}
            transition={{ duration: 0.5 }}
            variants={{
              hidden: { opacity: 0, x: -50 },
              visible: { opacity: 1, x: 0 },
            }}
          >
            {isAboveMediumScreens ? (
              <>
                <div className="flex items-center ml-5 mb-10">
                <img src="./assets/uofmlogo.png" className={`${imageStyles} mr-4 mb-10`} alt="uofm Icon" />
                <h2 className="text-center text-2xl mb-10">QN-MHP Software Engineer</h2>
              </div>
              <ul className="list-disc list-inside text-left text-l">
                <li>Implemented and visualized Queuing Network - Model Human Processor model using Python SimPy and Matlab Simulink/Simevents</li>
                <li>Generated, tested and analyzed user entity data input using QN-MHP model.</li>
              </ul>
              </>
            ) : (
              <>
                <div className="flex items-center ml-5 -mb-10">
                <img src="./assets/uofmlogo.png" className={`${imageStyles} mr-4 mb-10`} alt="uofm Icon" />
                <h2 className="text-center text-xl mb-10">QN-MHP Software Engineer</h2>
              </div>
              <ul className="list-disc list-inside text-left text-l -mb-5">
                <li>Implemented and visualized Queuing Network - Model Human Processor model using Python SimPy and Matlab Simulink/Simevents</li>
                <li>Generated, tested and analyzed user entity data input using QN-MHP model.</li>
              </ul>
              </>
            )}
          </motion.div>


          <motion.div
            className={`bg-yellow ${testimonialStyles}`}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.5 }}
            transition={{ duration: 0.5 }}
            variants={{
              hidden: { opacity: 0, x: -50 },
              visible: { opacity: 1, x: 0 },
            }}
          >
            {isAboveMediumScreens ? (
            <>
              <div className="flex items-center ml-5 mb-0">
                <img src="./assets/rokaflogo.png" className={`${imageStyles} mr-4 mb-10`} alt="uofm Icon" />
                <h2 className="text-center text-2xl mb-10">PATRIOT Missile Mechanic</h2>
              </div>
              <ul className="list-disc list-inside text-left text-l">
                <li>Performed general maintenance and interpreted error data from PATRIOT Missile.</li>
                <li>Worked as a member of the first PAC-3 PATRIOT Missile Enhancement Team in Korea and upgraded missile to PAC-3 version.</li>
                <li>Wrote error reports both in Korean and English</li>
              </ul>
            </>
            ) : (
              <>
                <div className="flex items-center ml-5 -mb-10">
                  <img src="./assets/rokaflogo.png" className={`${imageStyles} mr-4 mb-10`} alt="uofm Icon" />
                  <h2 className="text-center text-xl mb-10">PATRIOT Missile Mechanic</h2>
                </div>
                <ul className="list-disc list-inside text-left text-l -mb-8">
                  <li>Performed general maintenance and interpreted error data from PATRIOT Missile.</li>
                  <li>Worked as a member of the first PAC-3 PATRIOT Missile Enhancement Team in Korea and upgraded missile to PAC-3 version.</li>
                  <li>Wrote error reports both in Korean and English</li>
                </ul>
              </>
            )}
          </motion.div>

          
        </div>
      </section>
    )
}

export default Testimonials